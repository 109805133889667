import React from 'react';
import MzLogoStyles from "./mz-logo.module.scss";

const MzLogoE = () => {
    return (
        <svg className={MzLogoStyles["mz-logo__e"]} viewBox="0 0 75 95" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 3.49818C0 1.56619 1.56619 0 3.49819 0H69.4325C72.4307 0 74.8611 2.43045 74.8611 5.42857V5.42857C74.8611 8.42669 72.4307 10.8571 69.4325 10.8571H10.8889V46.1429H39.4881C42.4862 46.1429 44.9167 48.5733 44.9167 51.5714V51.5714C44.9167 54.5695 42.4862 57 39.4881 57H10.8889V84.1429H69.4325C72.4307 84.1429 74.8611 86.5733 74.8611 89.5714V89.5714C74.8611 92.5695 72.4307 95 69.4325 95H3.49818C1.56619 95 0 93.4338 0 91.5018V3.49818Z" fill="black"/>
            <path d="M55.8056 46.1429C58.8037 46.1429 61.25 48.5733 61.25 51.5714V51.5714C61.25 54.5695 58.8037 57 55.8056 57V57C52.8074 57 50.3611 54.5695 50.3611 51.5714V51.5714C50.3611 48.5733 52.8074 46.1429 55.8056 46.1429V46.1429Z" fill="black"/>
        </svg>
    );
};

export default MzLogoE;
