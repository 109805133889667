import React, {useEffect, useState} from 'react';

const UserInfo = () => {
    const [userItems, setUserItems] = useState({});

    useEffect(() => {

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const userUuid = urlParams.get('user_uuid');

        fetch(`${process.env.REACT_APP_API_HOST || "http://localhost:9000"}/api/user_info/`, {
            headers: {
                "user-UUID": userUuid
            }
        }).then(r => r.json()).then(res => {
            console.log(res)
            if (res.message === '{}') {
                setUserItems("not_found")
                return
            }
            setUserItems(JSON.parse(res.message))

        }).catch(() => {
            setUserItems("not_found")
        })
    }, [])
    if (userItems === "not_found")
        return (
            <section className="user-info">
                <h3>Пользователь не найден</h3>
            </section>
        )

    return (
        <section className="user-info">
            <div className="info-item">
                <h3>Пользователь</h3>
                <p>{userItems.organization || '...'}</p>
            </div>

            <div className="info-item">
                <h3>Тарифный план</h3>
                <p style={{color: userItems.plan === 'pro+' ? '#a073f4' : '#969696'}}>{userItems.plan || '...'}</p>
            </div>

            <div className="info-item">
                <h3>Статус</h3>
                <p style={{color: userItems.status === 'active' ? '#a073f4' : '#969696'}}>{userItems.status || '...'}</p>
            </div>

            <div className="info-item">
                <h3>Платформа</h3>
                <p>{userItems.platform || '...'}</p>
            </div>
        </section>
    );
};

export default UserInfo;
