import React from 'react';
import MzLogoStyles from "./mz-logo.module.scss";

const MzLogoO = (props) => {
    return (
        <svg {...props} className={MzLogoStyles["mz-logo__o"]}  viewBox="0 0 97 96" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M73.9928 80.664C72.0987 78.5068 68.8742 78.2166 66.3627 79.6101C51.5485 87.83 32.5041 84.9473 20.8957 71.726C20.2848 71.0303 19.7051 70.318 19.1565 69.5908C17.651 67.5953 15.1468 66.5027 12.7187 67.1091V67.1091C9.22342 67.9819 7.43235 71.8754 9.50135 74.8189C10.4827 76.215 11.5491 77.5725 12.701 78.8844C28.1164 96.4417 53.6479 99.9345 73.0369 88.298C75.7361 86.6781 76.0689 83.0286 73.9928 80.664V80.664Z" fill="black"/>
            <path d="M24.3587 20.0109C13.9718 29.0842 9.9312 42.6941 12.6104 55.2605C13.2848 58.4237 11.5859 61.7966 8.44141 62.5819V62.5819C5.77104 63.2488 2.99394 61.7955 2.32874 59.1306C-1.84534 42.4083 3.28621 23.9918 17.19 11.8462C31.1928 -0.385731 50.3431 -2.98157 66.4834 3.52442C69.0819 4.57185 70.1227 7.59181 69.0121 10.1579V10.1579C67.7453 13.0847 64.2156 14.2343 61.2124 13.1417C49.0154 8.70453 34.8236 10.8693 24.3587 20.0109Z" fill="black"/>
            <path d="M72.9547 14.7425C71.944 17.0776 72.6712 19.7691 74.4782 21.5647C75.0718 22.1546 75.6495 22.7687 76.2098 23.4069C87.8181 36.6281 88.1715 55.8383 78.049 69.4017C76.3329 71.7012 76.1961 74.9276 78.0901 77.0848V77.0848C80.1662 79.4494 83.8369 79.6016 85.8028 77.1465C99.924 59.5103 99.8199 33.8057 84.4044 16.2485C83.3195 15.0129 82.1845 13.8469 81.0051 12.7509C78.4108 10.3403 74.3597 11.4964 72.9547 14.7425V14.7425Z" fill="black"/>
        </svg>
    );
};

export default MzLogoO;
