import React from 'react';
import MzLogoSVG from "../assets/MzLogoSVG";

const Header = () => {
    return (
        <header className="header">
            <a href="https://morze.tech">
                <MzLogoSVG/>
            </a>
            <h2 className="header__title">Сведения о чат-боте</h2>
        </header>
    );
};

export default Header;
