import React from 'react';
import MzLogoStyles from "./mz-logo.module.scss";
const MzLogoM = (props) => {
    return (
        <svg {...props} className={MzLogoStyles["mz-logo__m"]} viewBox="0 0 95 95" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 89.5V7.28227C0 3.26039 3.26038 0 7.28227 0C9.61741 0 11.8109 1.11982 13.1805 3.01115L47.5 50.4048L82.3302 2.30588C83.379 0.857537 85.0588 0 86.847 0C91.4191 0 94.0484 5.1996 91.3384 8.88202L54.0865 59.5004C50.8343 63.9195 44.1967 63.8688 40.9567 59.4408L11 18.5V89.5C11 92.5376 8.53757 95 5.5 95C2.46243 95 0 92.5376 0 89.5Z" fill="black"/>
            <path d="M84 30.5V89.5C84 92.5376 86.4624 95 89.5 95C92.5376 95 95 92.5376 95 89.5V30.5C95 27.4624 92.5376 25 89.5 25C86.4624 25 84 27.4624 84 30.5Z" fill="black"/>
        </svg>
    );
};

export default MzLogoM;
