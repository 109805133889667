import React from 'react';
import MzLogoStyles from "./mz-logo.module.scss";
import MzLogoM from "./MzLogoM";
import MzLogoO from "./MzLogoO";
import MzLogoR from "./MzLogoR";
import MzLogoZ from "./MzLogoZ";
import MzLogoE from "./MzLogoE";

const MzLogoSVG = ({style}) => {
    return (
            <div className={`${MzLogoStyles['mz-logo']}`}>
                <MzLogoM/>
                <MzLogoO/>
                <MzLogoR/>
                <MzLogoZ/>
                <MzLogoE/>
        </div>
    );
};

export default MzLogoSVG;
