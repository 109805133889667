import React from 'react';
import MzLogoStyles from "./mz-logo.module.scss";

const MzLogoR = () => {
    return (
        <svg className={MzLogoStyles["mz-logo__r"]}  viewBox="0 0 75 95" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 22.5714C0 19.5733 2.44632 17.1429 5.44444 17.1429V17.1429C8.44256 17.1429 10.8889 19.5733 10.8889 22.5714V22.5714C10.8889 25.5695 8.44256 28 5.44444 28V28C2.44632 28 0 25.5695 0 22.5714V22.5714Z" fill="black"/>
            <path d="M9.53674e-07 89.5714C9.53674e-07 86.5733 2.44632 84.1429 5.44444 84.1429V84.1429C8.44256 84.1429 10.8889 86.5733 10.8889 89.5714V89.5714C10.8889 92.5695 8.44256 95 5.44444 95V95C2.44632 95 9.53674e-07 92.5695 9.53674e-07 89.5714V89.5714Z" fill="black"/>
            <path d="M10.8889 38.4444C10.8889 35.4376 8.45132 33 5.44444 33V33C2.43756 33 5.0852e-08 35.4376 1.1358e-07 38.4444L8.40094e-07 73.2698C9.02823e-07 76.2767 2.43756 78.7143 5.44444 78.7143V78.7143C8.45133 78.7143 10.8889 76.2767 10.8889 73.2698L10.8889 38.4444Z" fill="black"/>
            <path d="M5.42858 0C2.43046 0 9.53674e-07 2.43045 9.53674e-07 5.42857V5.42857C9.53674e-07 8.42669 2.43046 10.8571 5.42857 10.8571H49.0436C57.2884 10.8571 63.9722 17.5409 63.9722 25.7857V25.7857C63.9722 34.0305 57.2885 40.7143 49.0436 40.7143H21.7619C18.7638 40.7143 16.3333 43.1447 16.3333 46.1429V46.1429C16.3333 49.141 18.7638 51.5714 21.7619 51.5714H31.9337L65.8581 93.1348C66.8216 94.3152 68.2645 95 69.7882 95V95C72.5899 95 74.8611 92.7288 74.8611 89.9271V89.0755C74.8611 87.7103 74.3896 86.3869 73.5263 85.3293L45.9729 51.5714H49.0754C63.3164 51.5714 74.8611 40.0268 74.8611 25.7857V25.7857C74.8611 11.5447 63.3164 0 49.0754 0H5.42858Z" fill="black"/>
        </svg>
    );
};

export default MzLogoR;
