import React from 'react';
import MzLogoStyles from "./mz-logo.module.scss";

const MzLogoZ = () => {
    return (
        <svg className={MzLogoStyles["mz-logo__z"]} viewBox="0 0 94 95" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M77.5833 89.5714C77.5833 86.5733 75.137 84.1429 72.1389 84.1429V84.1429C69.1408 84.1429 66.6944 86.5733 66.6944 89.5714V89.5714C66.6944 92.5695 69.1408 95 72.1389 95V95C75.137 95 77.5833 92.5695 77.5833 89.5714V89.5714Z" fill="black"/>
            <path d="M93.9167 89.5714C93.9167 86.5733 91.4703 84.1429 88.4722 84.1429V84.1429C85.4741 84.1429 83.0278 86.5733 83.0278 89.5714V89.5714C83.0278 92.5695 85.4741 95 88.4722 95V95C91.4703 95 93.9167 92.5695 93.9167 89.5714V89.5714Z" fill="black"/>
            <path d="M87.8039 5.41387e-07C89.439 1.98035e-07 90.9911 0.720017 92.0471 1.96838V1.96838C93.8837 4.13955 93.785 7.34684 91.8185 9.40104L56.9475 45.8262C54.832 48.036 51.2681 47.9264 49.2923 45.5907V45.5907C47.5897 43.578 47.6811 40.6047 49.5042 38.7004L76.1594 10.8572H5.42857C2.43045 10.8572 0 8.42671 0 5.42859V5.42859C0 2.43047 2.43045 1.84689e-05 5.42857 1.78393e-05L87.8039 5.41387e-07Z" fill="black"/>
            <path d="M46.2095 57.0428C48.0325 55.1385 48.124 52.1652 46.4214 50.1524V50.1524C44.4456 47.8167 40.8817 47.7071 38.7662 49.917L0.90913 89.4613C0.325679 90.0708 0 90.8819 0 91.7256V91.7256C0 93.534 1.46598 95 3.27437 95H55.8214C58.8195 95 61.25 92.5695 61.25 89.5714V89.5714C61.25 86.5733 58.8195 84.1429 55.8214 84.1429H20.2657L46.2095 57.0428Z" fill="black"/>
        </svg>
    );
};

export default MzLogoZ;
