import Header from "./components/Header";
import UserInfo from "./components/UserInfo";
import Footer from "./components/Footer";

function App() {

    return (
        <div className="app">
            <Header/>
            <UserInfo/>
            <Footer/>
        </div>
    );
}

export default App;
