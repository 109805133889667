import React from 'react';

const Footer = () => {
    return (
        <footer className="footer">
            <p>© 2024 MORZE • All Rights Reserved</p>
        </footer>
    );
};

export default Footer;
